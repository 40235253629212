<template>
    <div class="flexPage bind-swift">
        <div class="flexHead">
            <navbar :params="pageInfo">
                <template #right>
                    <span @click="save" class="nav-right-text">{{ $t('other.translate109') }}</span>
                </template>
            </navbar>
        </div>
        <van-cell-group :border="false">
            <van-cell :title="$t('other.translate103')">
                <template #default>
                    <van-field v-model="formData.name" :placeholder="$t('other.translate103')" :border="false" input-align="right" />
                </template>
            </van-cell>
            <van-cell :title="$t('other.translate120')">
                <template #default>
                    <van-field v-model="formData.swiftAddress" :placeholder="$t('other.translate120')" :border="false" input-align="right" />
                </template>
            </van-cell>
            <van-cell :title="$t('other.translate104')">
                <template #default>
                    <van-field v-model="formData.jyPassword" :placeholder="$t('other.translate106')" type="password" :border="false"
                        input-align="right" />
                </template>
            </van-cell>
        </van-cell-group>
    </div>
</template>
<script>
import navbar from "@/views/modules/navbar.vue";

export default {
    data() {
        return {
            pageInfo: {
                customTitle: this.$t('other.translate101'),
                navbarStyle: "blue",
                backname: "payment",
            },
            formData: {
                name: '',
                swiftAddress: '',
                jyPassword: '',
            },
        }
    },
    components: { navbar },
    methods: {
        save() {
            let param = {};
            param["name"] = this.formData.name;
            param["swiftAddress"] = this.formData.swiftAddress;
            param["jyPassword"] = this.formData.jyPassword;
            this.$http.post(this.host + "/uc/approve/bind/swift", param).then((response) => {
                var resp = response.body;
                if (resp.code == 0) {
                    this.$toast(this.$t('other.translate110'));
                    this.$mts.goto('payment');
                } else {
                    this.$toast(resp.message);
                }
            });
        }
    }
}

</script>
<style>
.bind-swift {
    height: 100vh;
    background: #F8FAFD;
}

.bind-swift .flexHead .nav-right-text {
    color: #FFFFFF;
    font-size: 14px;
}

.bind-swift .van-cell-group .van-cell {
    display: flex;
    align-items: center;
}

.bind-swift .van-cell-group .van-cell .van-cell__value .van-field {
    padding: unset;
}

.bind-swift .van-cell-group .van-cell .van-cell__value .van-field .van-field__value .van-field__body input {
    margin-bottom: unset;
    border: unset;
    padding: unset;
    height: unset;
}

.bind-swift .van-action-sheet__content {
    height: 76.3359vw;
}

.bind-swift .van-action-sheet__cancel:before {
    display: none;
}
</style>